<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <WorkoutOverview :sessionId="sessionId" :clientId="clientId"></WorkoutOverview>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WorkoutSummary',
  components: {
    WorkoutOverview: () => import('@/components/WorkoutOverview'),
  },
  computed: {
    sessionId() {
      return parseInt(this.$route.params.sessionId, 10);
    },
    clientId() {
      return this.$route.params.clientId;
    },
  },
};
</script>
